<template>
  <AdminReportingForm
    title="Occupancy By Product Class"
    description="The Occupancy By Product Class Report is at the park level and provides
      occupancy numbers and occupancy percentages for each product class within
      the park for the date range specified. This report can be run for any 365 day date range."
    :bullets="descriptionBullets"
    file="OccupancyByProductClass$"
    :reportStatus="reportStatus"
    :submitting="submitting"
    :allowGlobal="true"
    @getReport="getReport"
    startDateLabel="Occupancy Start Date"
    endDateLabel="Occupancy End Date"
  />
</template>

<script>
import AdminReportingForm from "@/components/admin/AdminReportingForm.vue";
import AdminReportingService from "@/services/admin/AdminReportingService.js";
import moment from "moment";

export default {
  name: "OccupancyByProductClassReport",
  title: "Admin - Occupancy By Product Class Report",
  data() {
    return {
      reportStatus: "",
      submitting: false,
      descriptionBullets: [
        "Number of reservations and number of nights include Active reservation counts only.",
        "All Revenue fields includes collections from cancelled reservations also.",
        "Revenue column includes use fee after discount.",
        "Report includes the reservations counts for the night beginning with Occupancy start date and ending with Occupancy end date - both nights included.",
        "Shelter information is reported in days."
      ]
    };
  },
  components: {
    AdminReportingForm
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  },
  methods: {
    getReport(reportData) {
      this.reportStatus = "";
      this.submitting = true;
      const date = moment();
      reportData.dateRun = date.format("MM/DD/YYYY hh:mm:ss A");

      const service = new AdminReportingService(this.tenantId);
      service
        .getOccupancyByProductClassReport(reportData)
        .then(response => {
          if (response.deliveryMethod == "Download") {
            const filename = reportData.filename.replace(
              "$",
              date.format("MM_DD_YYYY_hh_mm_ss_A")
            );
            service.forceFileDownload(response, filename);
          } else {
            this.reportStatus = "Report sent!";
          }
        })
        .finally(() => {
          this.submitting = false;
        });
    }
  }
};
</script>
